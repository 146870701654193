/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import '../css/external.css';
//import '../css/print.css';
import 'leaflet/dist/leaflet.css';

import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import "@fancyapps/ui/dist/fancybox.css";

import Talk from 'talkjs'

//load custom template
const template = document.getElementById('css-specifications').dataset.template;
if (template) {
    import('../css/templates/' + template + '/' + template + '.css');
}

require('@mdi/font/css/materialdesignicons.min.css');

function loadPicture(pictureHover, picture) {
    pictureHover.show();
    pictureHover.html('<img src=\"' + picture + '\"/>');
    const top = 250 + parseFloat(pictureHover.position().top) - parseFloat($(window).scrollTop());
    const windowHeight = parseFloat($(window).height());
    if (top > windowHeight) {
        pictureHover.css('margin-top', '-' + (230 + top - windowHeight) + 'px');
    }
}

$(document).ready(function () {
    //Talkjs
    Talk.ready.then(function () {
        const dataElem = document.getElementById('talkjs-data');
        if (!dataElem) return;
        const data = dataElem.dataset;

        /* Contact settings */
        const me = new Talk.User({
            id: data.meId,
            name: data.meName,
            email: data.meEmail,
            locale: data.meLocale,
            role: 'customer',
        });

        /* Agent settings */
        const agent = new Talk.User({
            id: data.agentId,
            name: data.agentName,
            email: data.agentEmail,
            photoUrl: data.agentPhoto || null,
            role: 'broker'
        });

        window.session = new Talk.Session({
            appId: '62lLBh68',
            me: me,
            signature: data.signature
        });

        const conversation = session.getOrCreateConversation(
            Talk.oneOnOneId(me, agent)
        );
        conversation.setParticipant(me);
        conversation.setParticipant(agent);
        const popup = session.createPopup();
        popup.select(conversation);
        popup.mount({ show: false });

        popup.onSendMessage(function(conversationData)
        {
            conversationData.override({
                text: conversationData.message.text,
                custom: {
                    propertyId: data.propertyId || '',
                    contactId: data.meId,
                }
            });
        });

        const button = document.getElementById('btn-getInTouch');
        button.addEventListener('click', (event) => {
            event.preventDefault();
            if (button.classList.contains('open')) {
                button.classList.remove('open');
                button.classList.add('closed');
                popup.hide();
            } else {
                button.classList.remove('closed');
                button.classList.add('open');
                popup.show();
            }
        });
    });

    //remove query parameters from url
    let url = window.location.href;
    let url_parts = url.split('?');
    if (url_parts.length > 1) {
        window.history.replaceState({}, '', url_parts[0]);
    }

    $('table.listing tbody tr > td:not(.not)').click(function () {
        if ($('a.gotoBtn', $(this).parent()).length > 0) {
            $('a.gotoBtn', $(this).parent())[0].click();
        }
    });

    //Contact form
    $('#contactForm').submit(function(e) {
        e.preventDefault();
        const form = $(this);
        const formData = form.serialize();
        form.find('input').attr('disabled', true);
        const formError = form.find('.error');
        const formSuccess = form.find('.success');
        formError.hide();

        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: formData,
            success: function (data) {
                if (data.success) {
                    formSuccess.html(data.message).show();
                } else {
                    formError.html(data.message).show();
                    $(this).find('input').attr('disabled', false);
                }
            },
            error: function () {
                formError.html("Unknown error, please retry later.").show();
                $(this).find('input').attr('disabled', false);
            },
        });
    });

    /* Language & Currency menu */
    $('header nav ul.language li').each(function () {
        $('a', $(this)).text($(this).attr('data-lang'));
    });

    $('header nav ul.language li.s a').click(function () {
        $('header nav ul.language').toggleClass('active');
        return false;
    });

    $('header nav ul.currency li.s a').click(function () {
        $('header nav ul.currency').toggleClass('active');
        return false;
    });

    $('header nav ul.language.active li a, header nav ul.currency.active li a').click(function (e) {
        e.stopPropagation();
    });

    $(document).on('click', function (event) {
        if (!$(event.target).closest('header nav ul.language').length) {
            $('header nav ul.language').removeClass('active');
        }
        if (!$(event.target).closest('header nav ul.currency').length) {
            $('header nav ul.currency').removeClass('active');
        }
    });

    $('header nav button').click(function () {
        $(this).add('header nav').toggleClass('active');
    });

    if ($('div.likeit').length > 0) {
        $('div.likeit svg .star').hover(function () {
            const number = $(this).data('number');
            if ($('div.likeit').data('filled') != true) {
                if (number >= 1) {
                    $('.star1').css('fill', 'url(#star_gradient_1_0)');
                }
                if (number >= 2) {
                    $('.star2').css('fill', 'url(#star_gradient_2_0)');
                }
                if (number >= 3) {
                    $('.star3').css('fill', 'url(#star_gradient_3_0)');
                }
                if (number >= 4) {
                    $('.star4').css('fill', 'url(#star_gradient_4_0)');
                }
                if (number >= 5) {
                    $('.star5').css('fill', 'url(#star_gradient_5_0)');
                }
            }
        }, function () {
            if ($('div.likeit').data('filled') !== true) {
                $('.star').css('fill', 'rgb(200, 200, 200)');
            }
        });

        $('div.likeit svg .star').click(function () {
            $('.star').css('fill', 'rgb(200, 200, 200)');
            const number = $(this).data('number');
            if (number >= 1) {
                $('.star1').css('fill', 'url(#star_gradient_1_0)');
            }
            if (number >= 2) {
                $('.star2').css('fill', 'url(#star_gradient_2_0)');
            }
            if (number >= 3) {
                $('.star3').css('fill', 'url(#star_gradient_3_0)');
            }
            if (number >= 4) {
                $('.star4').css('fill', 'url(#star_gradient_4_0)');
            }
            if (number >= 5) {
                $('.star5').css('fill', 'url(#star_gradient_5_0)');
            }
            $('div.likeit').attr('data-filled', 'true');

            $.ajax({
                url: window.location.href,
                data: {star: number}
            });
        });
    }

    if ($('section.owner').length > 0) {
        window.addEventListener("hashchange", function () {
            window.scrollTo(window.scrollX, window.scrollY - 100);
        });
    }

    // thumb picture
    $('.crossing-picture').each(function () {
        $(this).mouseover(function () {
            loadPicture($('.pictureHover', $(this).parent()), $(this).attr('data-picture-zoom'));
        });
        $(this).mouseout(function () {
            $('.pictureHover', $(this).parent()).hide();
        });
    });

    //switch language
    $('#languageSwitch').change(function () {
        window.location.href = '/l/' + $(this).val();
    });
});